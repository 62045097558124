*{
    transition: all 100ms;
}
.side-container{
    background-color: #ffffff;
    max-width: 300px;
    height: auto;
    box-shadow: 2px 5px 5px gainsboro;
    padding: 0 1em;
    z-index: 100;
}
.side-item{
    display: flex;
    justify-content: start;
    color: #626262;
    cursor: pointer;
    border-radius: 12px;
    padding: 1em 2em;
    margin: 0.5em 0;
}
.side-item:hover{
    background-color: #E7EEFD;
}
.side-item i{
    font-size: 25px;
}
.side-item p{
    font-size: 16px;
    margin: 0;
    padding: 0 1em;
    text-wrap: nowrap;
}

.active{
    background-color: #E7EEFD;
    font-weight: 600;
}

.side-user{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding: 1em;
}
.side-user div{
    height: 50px;
    width: 50px;
    border-radius: 100%;
}
.side-user p{
    font-size: 18px;
    font-weight: 900;
    margin: 0;
    padding-left: 1em;
    background: none;
    color: #303950 !important;
    cursor: pointer;
}

.side-actions{
    display: flex;
    justify-content: end;
    padding-top: 2em;
    padding-bottom: 1em;

}

#right-arrow{
    display: none;
    cursor: pointer;
}
#left-arrow{
    cursor: pointer;
}
.side-responsive-close{
    display: none;
}
.side-responsive-open{
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    
    padding: 0.5em ;
    font-size: 40px;
}
@media screen and (max-width: 660px) {
    #side-bar{
        transform: translateX(0);
        will-change: transform;
    }
    #side-bar.hidden {
        transform: translateX(-100%); /* Se desplaza fuera de la pantalla */
    }

    .side-container{
        position: fixed;
        top: 0;
        left: 0;
    }
    .side-responsive-close{
        display: flex;
        font-size: 25px;
        justify-content: center !important;
    }

    #left-arrow{
        display: none !important;
    }
    #right-arrow{
        display: none !important;
    }
    .side-responsive-open{
        display: block;
    }
}