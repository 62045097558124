html{
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}
#root{
  flex: 1;
}
main{
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --primary-color: #303950;
  --secondary-color: #F1F5FF;
  --font-size-base: 16px;
  --font-family: 'Arial, sans-serif';
  --padding: 10px;
}
