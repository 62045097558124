.home-main-container{
    width: 100%;
    padding: 3em 5em;
}
.home-card{
    background-color: #ffffff;
    border-radius: 7px;
    box-shadow: 2px 2px 5px #c3c3c3;
    /* min-width: 300px ; */
    width: 300px ;
    margin: 0.6em auto;
    max-height: 170px;
    /* display: flex;
    padding: 1em; */
}
.home-card i{
    font-size: 55px;
}
.home-search-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2em 1em;
}

.home-search-card-item{
    font-size: 13px;
    color: #333333;
    margin: 0;
    cursor: pointer;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.home-search-card-item:hover{
    text-decoration: underline;
    color: #303950;
}

.home-search-card-detail{
    font-size: 10px;
    color: #ACACAC;
    margin: 0;
    text-wrap: nowrap;
}

.home-search-header{
    font-size: 16px;
    font-weight: 900;
    border-bottom: 1px solid #d8d8d8;
    padding: 0.8em 1em;
}
.home-search-header i{
    font-size: 16px;
}


.search-tracker-title{
    font-size: 16px;
    color: #000000;
    margin: 0;
    text-align: center;
    font-weight: 600;
}

.search-tracker-subtitle{
    font-size: 14px;
    color: #000000;
    margin: 0;
    text-align: center;
}

.search-tracker-actions{
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    width: fit-content;
    background-color: #D9D9D9;
    border-radius: 16px; 
    font-size: 14px;
    padding: 0.2em 0.5rem;
    margin-left: 2rem;
    /* transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; */
}
.search-tracker-actions i{
    color: #606060;
    padding: 0 0.5rem;
    cursor: pointer;
}
.search-tracker-actions i:hover{
    color: #3E5BC0;
}

.react-grid-placeholder{
    background-color: #303950 !important;
}



.dashboard-home-list{
    background: #ffffff;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    padding: 1em 1em;
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin: 0.5em;
}
.dashboard-home-image{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5em;
}
.dashboard-home-meta{
    flex: 1;
}
.dashboard-home-date{
    font-size: 12px;
    color: #546184;
    margin: 0;
    text-align: end;
}
.dashboard-home-title{
    font-size: 15;
    font-weight: 600;
    color: #333333;
    margin: 0;
    cursor: pointer;
}
.dashboard-home-title:hover{
    color: #303950;
    text-decoration: underline;
}

.dashboard-home-description{
    font-size: 13px;
    color: #888888;
    margin: 0;
}

@media screen and (max-width: 660px) {
    .home-main-container{
        padding: 2em 0;
    }
}

