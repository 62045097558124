.modal-container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.6);
    z-index: 1000;
}
/* .modal-container *{
    border: 1px solid red
} */
.modal-window{  
    background-color: #ffffff;
    border-radius: 30px;
    box-sizing: border-box;
    max-width: 40em;
}

.login-label{
    font-size: 16px;
    margin: 0;
    padding: 0.2em 0.2em;
    font-weight: 700;
}

.login-input{
    display: flex;
    align-items: center;
    border: 1px solid #D9D9D9;
    padding: 0.5em 1em;
    border-radius: 10px;

}
.login-input input{
    border: none;
    font-size: 16px;
}
.login-input input:focus{
    outline: none;
}


.login-signup{
    text-align: center;
    font-size: 15px;
    color: #717171;
}
.login-signup span{
    cursor: pointer;
    color: #4763AB
}
.login-signup span:hover{
    text-decoration: underline;
}

.login-button{
    font-size: 16px;
    background-color: #3D5184;
    color: #ffffff;
    width: 9em;
    padding: 0.3em;
    text-align: center;
    border-radius: 5px;
    box-shadow: none;
    cursor: pointer;
}

.login-card{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #E7E7E7;
    padding: 1em;
    border-radius: 30px;
    cursor: pointer;
}
.login-card p{
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}


.forgot-p{
    font-size: 18px;
    color: #6A6A6A;
}

.chart-p{
    font-size: 16px;
    
}

.chart-text{
    font-size: 12px;
    font-weight: 600;
    text-align: center;
}

.login-validator{
    font-size: 14px;
    margin: 0;
    padding: 0;
}
.achieved{
    color: #3E5BC0 !important;
    font-weight: 600 !important;
}
.login-validator-bar{
    width: 100%;
    background-color: #D9D9D9;
    border-radius: 10px;
    height: 12px;
}
.login-validator-bar div{
    /* width: 50%; */
    background-color: #3E5BC0;
    height: 100%;
    border-radius: 10px;
}