*{
    font-family: 'Open Sans';
    color: var(--primary-color);
    /* border: 1px solid red !important; */
}
body{
    font-size: 19px;
}
a{
    text-decoration: none;
    color: var(--primary-color);
}
a:hover{
    text-decoration: underline;
}

/* °°°°°°°°°°°°°°° HEADER °°°°°°°°°°°°°°° */
.header{
    /* height: 700px; */
    padding-bottom: 5em;
    border-bottom-left-radius: 55px;
    border-bottom-right-radius: 55px;
    background-image: url('../../public/images/home-header.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #021927;
    filter: none !important;
}
.header-title{
    padding-left: 3rem;
    text-wrap: nowrap;
}
.nav-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em 3em;
}

.nav-bar a{
    color: white;
    text-decoration: none;
    padding: 0 2em;
    font-size: 18px;
    font-weight: 100;
}
.nav-bar span{
    color: white;
    text-decoration: none;
    padding: 0 2em;
    font-size: 18px;
    font-weight: 100;
    cursor: pointer;
}

.main-title{
    color: #ffffff !important;
    font-size: 28px !important;
    font-weight: 700;
}
.container{
    max-width: 1200px;
    margin: 0 auto;
}
.main-button{
    background: #ECF1FF;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 500;
    padding: 10px 40px;
    border: 2px solid #ffffff;
}

.bold-title{
    color: #fff;
    font-size: 55px;
    font-weight: 900;
}
.main-subtitle{
    color: #fff;
    font-size: 20px;
    font-weight: 300;
}

.header-input{
    background-color: #ffffff;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    padding: 4px;
}

/* .header-input > div:nth-child(1){
    background-color: #D5D5D5;
    height: 100%;
    border-radius: 50px;
    display: flex;
    align-items: center;
    color: #525867;
    padding: 0 20px;
} */
.header-input-button{
    background-color: #D5D5D5;
    height: 100%;
    border-radius: 50px;
    display: flex;
    align-items: center;
    color: #525867;
    padding: 0 20px;
    border: none;
    /* margin: 2px; */
}
.header-input-button::after{
    display: none;
}
.header-input-text{
    flex: 1;
    padding: 0 5px;
}
.header-input-text input{
    width: 100%;
    border: none;
}

.header-input-text input:focus{
    outline: none;
}

.header-input-icon{
    padding-right: 1em;
    padding-left: 0.5em;
}

/* .header-input > div:nth-child(2){
    flex: 1;
    padding: 0 5px;
} */
/* .header-input > div:nth-child(2) input{
    width: 100%;
    border: none;
} */
/* .header-input > div:nth-child(2) input:focus{
    outline: none;
} */

.header-tag{
    color: #ffffff;
    font-weight: 100;
    font-size: 15px;
    padding: 5px 10px;
}

.normal-title{
    font-weight: 900;
    font-size: 40px;
}
.normal-p{
    font-size: 26px;
}


.why-content{
    display: flex;
    align-items: center;
    max-width: 400px;
    padding: 2em 0;
}
.why-content p{
    font-weight: 100;
    font-size: 18px;
}

.about-content{
    display: flex;
    justify-content: start;
}
.about-content div{
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 2em 0;
}
.about-content h1{
    font-size: 35px;
    font-weight: 900;
    padding: 0;
    margin: 0;
}
.about-content p{
    padding: 0;
    font-size: 22px;
    font-weight: 100;
    margin: 0;
}

.structure-content{
    display: flex;
    height: 200px;
    border: 1px solid #AAAAAA;
    box-shadow: 5px 3px 0px #D9D9D9;
    padding: 1em;
    background-color: #F1F5FF;
    cursor: pointer;

}
.structure-content img{
    width: 90px;
    height: auto;
    margin: 0 20px;
}
.structure-content div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.structure-container{
    max-width: 1400px;
    margin: 0 auto;
}



.api-card{
    background-color: #F1F5FF;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    box-shadow: 0px -10px 0px #D7DFF3;
}

.text-code{
    background-color: #333333 ;
    padding: 1.5em;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 100%;
    color: white;
    font-family: Consolas;
    font-size: 14px;
}
.text-code span{
    color: #fff;
}
.text-code i{
    color: #fff;
}





.login-forgot{
    color: #717171;
    font-weight: 400;
}


.login-header{
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-image: url('../../public/images/modal-header.svg');
    background-size: cover;
    background-position:bottom;
    background-repeat: no-repeat;
}



.trans-card{
    padding: 5em 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#api-text{
    overflow-wrap: break-word;
}

.pas-5{
    padding-left: 3rem;
}

.home-select-container{
    width: 100%;
    border: none;
  }

.home-search-trend{
    color: #fff;
    cursor: pointer;
}

.home-search-trend:hover{
    text-decoration: underline;
}



  .home-select__control{
    background-color: transparent !important;
    box-shadow: none !important;
    /* border: transparent !important; */
    border: none !important;    
    /* padding: 0.2em 0.3em; */
    color: var(--primary-color);
  }
  .home-select__control--is-focused{
    border: none !important;
  }
  
  .home-select__option {
    font-size: 14px !important;
    font-family: 'Open Sans' !important;
  }

  .home-select__indicator{
    display: none !important;
  }

  .home-select__placeholder {
    /* font-size: 16px !important; */
    color: #757C8F !important;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  
  .css-1gtu0rj-indicatorContainer {
    display: none;
  }

  .no-chevron .dropdown-toggle::after {
    display: none;
  }

  .home-login-responsive{
    display: none;
  }




@media screen and (max-width: 660px) {
    body{
        font-size: 17px;
    }
    .bold-title{
        font-size: 25px;
        text-align: center;
    }
    .api-link{
        display: none;
    }
    .home-account{
        display: none;
    }
    .main-subtitle{
        font-size: 20px;
        text-align: center;
    }
    .main-title{
        font-size: 25px;
    }
    .normal-title{
        font-size: 30px;
    }
    .normal-p{
        font-size: 18px;
    }

    .nav-items{
        display: none;
    }
    .nav-bar{
        padding: 2em 1em;
    }
    .header-title{
        padding: 0;
    }

    .api-image{
        justify-content: center;
    }
    .api-image img{
        width: 350px;
    }

    .about-content{
        display: flex;
        justify-content: center;
    }
    .about-center{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    .about-center p{
        text-align: center;
    }
    .pas-5{
        padding: 0rem;
    }

    .trans-card{
        padding: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .header-buttons{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .header-buttons button{
        margin: 0.5em;
    }
    .home-login{
        display: none;
    }
    .home-login-responsive{
        display: block !important;
        padding-right: 0.8em;
    }

    .header-input-button{
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    

}