.p-title{
    font-size: 35px;
    font-weight: 900;
    color: #333333;
}

.p-subtitle{
    font-size: 25px;
    font-weight: 700;
    color: #333333;
}

.p-paragraph{
    font-size: 16px;
    color: #333333;
}
.p-label{
    font-size: 16px;
    font-weight: 700;
    color: #333333;
}
.p-input{
    font-size: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 0.4em 1em;
}
.p-input::placeholder{
    font-size: 16px;
    color: #B3B3B3;
}
.p-button{
    background-color: #546184;
    font-size: 15px;
    color: #ffffff;
    border-radius: 6px;
    padding: 0.5em 1.5em;
    width:fit-content;
    cursor: pointer;
}
.p-button-2{
    display: flex;
    align-items: center;
    font-size: 16px;
    background: #2C2C2C;
    color: #ffffff;
    border-radius: 9px;
    padding: 0 0.8em;
    cursor: pointer;
}

.temp{
    height: 80px;
    width: 80px;
    background: gray;
    border-radius: 100%;
}

.p-select{
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    font-size: 16px;
    color: #000000;
    padding: 0.5em 0.8em;    
}
.p-select option {
    /* background-color: #D9D9D9; */
    color: #333333;
}
.p-select option:hover {
    background-color: #D9D9D9;
    /* color: #333333; */
}

.p-text-area{
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    font-size: 16px;
    color: #000000;
    padding: 0.5em 0.8em;    
}
.p-text-area::placeholder{
    color: #B3B3B3;
}
.p-label-2{
    font-size: 16px;
}
.table-icon{
    color: #7A7A7A;
    font-size: 18px;
    padding: 0 0.3em;
    cursor: pointer;
}

.avatar-container img{
    margin: 0.3em 0.1em;
    padding: 0;
    cursor: pointer;
}
.active-avatar{
    /* background: red; */
    border: 3px solid red;
    border-radius: 100%;
}
.user-avatar{
    border: 3px solid #3D5184 !important;
    border-radius: 100%;
}

.dashboard-avatar-active{
    /* background: red; */
    border: 3px solid red;
    border-radius: 10px;
}
.dashboard-avatar{
    border: 3px solid #3D5184 !important;
    border-radius: 10px;
}
