.footer{
    font-size: 15px;
}
.pre-footer-nav{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.pre-footer-nav a{
    padding: 8px;
}
.pre-footer-nav p{
    font-size: 15px;
}

.search-header{
    display: flex;
    justify-content: space-between;
    padding: 1.5em 2em;
    color: #fff;
    background: linear-gradient(to left, #5E9090,#303950);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}
.search-header-image{
    height: 2.5rem;
    width: auto;
    cursor: pointer;
}
.search-header-title{
    color: #fff;
    font-weight: 900;
    font-size: 28px;
    padding-left: 3rem;
    text-wrap: nowrap;
}
.search-header-nav {
    display: flex;
}
.search-header-nav div{
    color: #fff;
    padding: 0 3rem;
    font-size: 18px;
    font-weight: 100;
}
.search-header-nav-dropdown{
    color: #fff;
    padding: 0;
    font-size: 18px;
    font-weight: 100;
}

.search-header-nav a{
    color: #fff !important;
    padding: 0 3rem;
    font-size: 18px;
    font-weight: 100;
}
.search-header-right{
    display: flex;
    align-items: center;
}

.search-title{
    /* color: var(--primary-color); */
    color: var(--primary-color);
    font-size: 45px;
    font-weight: 900;
}

.comment{
    color: #74809D;
    font-size: 18px;
    font-weight: 500;
}
.little-p{
    font-size: 13px;
    font-weight: 400;
}

.search-label{
    font-size: 20px;

    font-weight: 700;
    margin: 0.4em 0;
}
.search-input{
    font-size: 16px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    padding: 0.5rem 1rem;
}


.sort-container{
    background-color: #F2F2F2;
    display: flex;
    border-radius: 5px;
    padding: 0.2em;
}
.sort-container div{
    font-size: 14px;
    padding: 0.2em 2em;
    color: #646464;
    cursor: pointer;
}

.sort-selected{
    background: #fff;
    border-radius: 5px;
    box-shadow: 1px 1px 3px gainsboro;
    font-weight: 500;
}



.search-card{
    width: 100%;
    margin-top: 1em;
    border: 1px solid #D9D9D9
}
.search-card-title{
    font-size: 19px;
    font-weight: 700;
}

.search-card-summary{
    font-size: 14px;
    color: #333333;
}

.search-card-label{
    font-size: 15px;
    font-weight: 700;
    margin: 0;
}
.search-card-select{
    background: transparent;
    border: 1px solid #D9D9D9;
    color: #4C4C4C;
    border-radius: 10px;
    padding: 0.2em 0.7em;
}

.search-card-select:hover{
    background-color: transparent;
    color: #4C4C4C;
}
.search-card-select:active{
    background-color: red;
}
/* .search-card-select{
    background-color: red;
} */
.search-card-select::after{
    color: #8D8D8D;
    margin-left: 2em;
}

.search-card-select-item{
    font-size: 15px;
    color: #333333;
}


.search-p{
    font-size: 15px;
    margin: 0;
}

.search-card-button{
    font-size: 14px;
    color: #4C4C4C;
    background-color: transparent;
    border-radius: 10px;
    padding: 0.4em 2em;
    border: 2px solid #313A52;
    transition: background-color 0.1s;
}
.search-card-button:hover{
    background-color: #313A52;
    color: #ffffff;
}
.search-card-button:hover i{
    
    color: #ffffff;
}


  .favorite{
    cursor: pointer;
  }
  .favorite:hover{
    color: #ffb300;
  }


  .message-component{
    border: 1px solid red;
  }

  .button-disabled{
    pointer-events: none;
    opacity: 0.5;
  }

