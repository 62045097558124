.dashboard-main-container{
    width: 100%;
    padding: 3em 5em;
}

.default-widget{
    background-color: #ffffff;
    border-radius: 5px;
    max-width: 350px;
    height: 150px;
    box-shadow: 1px 1px 5px #c3c3c3;
    cursor: pointer;
}
.default-widget:active{
    cursor: grabbing;
}




.default-widget-left{
    padding: 0.3em 0;
    box-sizing: border-box;
    background-color: #636D87;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.default-widget-title{
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    margin: 0;
}
.default-widget-description{
    color: #ffffff;
    font-size: 15px;
    text-align: center;
    font-weight: 100;
}
.default-widget-content{
    color: #333333;
    font-size: 25px;
    text-align: center;

}


.dwidget-container{
    background-color: #ffffff;
    /* border: 1px #cccccc; */
    /* border-style: solid; */
    border-radius: 5px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 5px #cccccc;
    animation: vibrate 0.5s linear infinite; /* Animación de vibración continua */
}

/* .dwidget-container::before{
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border: 2px dashed #000;
    border-radius: 4px;
    animation: move-dash 2s linear infinite;

} */

/* @keyframes move-dash {
    0% {
      border-style: dashed;
      border-width: 2px;
      transform: translateX(0);
    }
    100% {
      border-style: dashed;
      border-width: 2px;
      transform: translateX(20px);
    }
  } */

.dwidget-chart-title{
    font-size: 17px;
    color: #333333;
    margin: 0;
    padding: 0;
    font-weight: 700;
    text-align: center;
}

.dwidget-chart-subtitle{
    font-size: 15px;
    color: #949494;
    margin: 0;
    padding: 0;
    text-align: center;
    /* font-weight: 700; */
}

.dwidget-indicator-title{
    font-size: 18px;
    color: #333333;
    text-align: center;
    margin: 0;

}
.dwidget-indicator-value{
    font-size: 30px;
    color: #333333;
    font-weight: bold;
    text-align: center;
    margin: 0;
}
.dwidget-indicator-unit{
    font-size: 17px;
    color: #bdbdbd;
    text-align: center;
    margin: 0;
}

.dwidget-indicator-time{
    font-size: 17px;
    color: #333333;
    text-align: center;
    margin: 0;
    /* font-weight: 700; */
}
.dwidget-indicator-country{
    font-size: 16px;
    color: #bdbdbd;
    text-align: center;
    margin: 0;
    /* font-weight: 700; */
}

.tester{
    /* font-family: 'Ope'; */
    font-size: 12px;
    color: #525252;
    font-weight: 100;

    /* align-items: center;
    text-align: center; */
}

.testi{
    /* background: red; */
    width: 100%;

    position: absolute;

    right: 0;
}

.big-loader{
    position:sticky ;
    background: rgba(255,255,255,0.6);
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.dashboard-edit-container{
    padding: 0.2em;
    box-sizing: content-box;
    background-color: transparent;
    background-image:
        radial-gradient(circle,  #808080 0%, transparent 20%),
        radial-gradient(circle, #808080 0%, transparent 20%);
    background-size: 5px 5px;
    height: 100%;
    width: 100%;
}

.dashboard-item-list{
    background: #ffffff;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    padding: 1em 1em;
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin: 0.5em 0;
}
.dashboard-item-image{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5em;
}
.dashboard-item-meta{
    flex: 1;
}
.dashboard-item-date{
    font-size: 12px;
    color: #546184;
    margin: 0;
    text-align: end;
}
.dashboard-item-title{
    font-size: 15;
    font-weight: 600;
    color: #333333;
    margin: 0;
    cursor: pointer;
}
.dashboard-item-title:hover{
    color: #303950;
    text-decoration: underline;
}

.dashboard-item-description{
    font-size: 13px;
    color: #888888;
    margin: 0;
}

.default-widgets-container{
    position: -webkit-sticky;
    position: sticky;
    /* background-color: red; */
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    padding: 0 1em;
    transition: all 0.2s ease;
}

.sticky-active {
    padding: 1em;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
  }

@media screen and (max-width: 660px){
    .dashboard-main-container{
        padding: 1em 1em;
    }
}

@media print {

    @page {
    margin: 0;
  }
    /* Eliminar el overflow de los contenedores */
    .w-100 {
        background: #ffffff;
        color: transparent;
    }
    body, html {
      color: transparent;
      overflow: visible !important;
      height: auto !important;
      margin: 0; /* Eliminar márgenes */
        padding: 0; /* Eliminar relleno */
        width: 100%; /* Asegurar que el ancho sea 100% del viewport */
        height: 100%; /* Permitir que la altura se ajuste automáticamente */
    }

    /* Evitar el scroll en los contenedores */
    .react-grid-layout {
        display: block;
        transform: scale(0.8);
        overflow: visible !important;
        height: auto !important;
        width: 100vw; /* Asegurar que el ancho sea 100% del viewport */
        height: 100%; /* Permitir que la altura se ajuste automáticamente */
      }



    /* Ocultar elementos que no quieras imprimir, como barras laterales o de navegación */
    .side-container, .navbar, .footer {
      display: none !important;
    }

    /* Ajustar el tamaño de los contenedores principales para que no creen scroll */
  .main-content {
    overflow: visible !important;
    height: auto !important;
  }

  /* Asegurar que los elementos importantes para la impresión se muestren correctamente */
  .printable-section {
    display: block !important;
  }

  .react-grid-layout {
    position: relative !important; /* Cambia de absolute a relative para que se ajuste a la página */
    height: auto !important; /* Asegurar que el contenedor pueda crecer para imprimir todo el contenido */
  }


  /* Evitar cortes de contenido en las columnas o filas */
  .react-grid-layout > * {
    page-break-inside: avoid;

  }

  }
