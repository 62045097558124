.help-main-container{
    width: 100%;
}
.help-header{
     padding-top: 4.5em;
     padding-bottom: 2em;
     background-image: url('../../public/images/help-header.png');
     background-size: cover;
     background-position: center;
     background-repeat: no-repeat; 
}

.help-title{
    font-size: 40px;
    color: #ffffff;
    font-weight: 800;
    text-align: center;
}

.help-input-container{
    background-color: #ffffff;
    border-radius: 50px;
    max-width: 420px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2em 1em;
    margin: 0 auto;
}
.help-input{
    background: transparent;
    border: none;
    flex: 1;
}
.help-input:focus{
    outline: none;
}
.help-input::placeholder{
    color: #B3B3B3;
    font-size: 16px;
}

.help-tags{
    font-size: 15px;
    color: #ffffff;
    text-align: center;
    font-weight: 400;
    /* text-decoration: underline; */
}
.help-tags span{
    color: #fff;
    font-weight: 500;
    
}

.help-subtitle{
    font-size: 24px;
    color: #1E1E1E;
    font-weight: 600;
    margin: 0;
}

.help-paragraph{
    font-size: 16px;
    color: #757575;
}


.help-card-container{
    background-color: #434343;
    border-radius: 10px;
    padding-bottom: 0.2em;
    max-width: 360px;
    cursor: pointer;
    margin: 1em;
    display: flex;
    flex-direction: column;
}
.help-card-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.4em 1em;
}
.help-card-header p{
    color: #ffffff;
    font-size: 11px;
    font-weight: 100;
    margin: 0;
}
.help-card-header i{
    color: #ffffff;
    font-size: 12px;
}

.help-card-body{
    background-color: #ffffff;
    border-radius: 10px;
    margin-left: 0.2em;
    margin-right: 0.2em;
    padding: 1em;
    flex: 1;
}

.help-card-icon{
    color: #585858;
    font-size: 50px;
    padding-left: 0.3em;
    padding-right: 0.5em;
    /* padding: 0 0.5em; */
}
.help-card-title{
    font-size: 15px;
    color: #333333;
    font-weight: 600;
    margin: 0;
}
.help-card-paragraph{
    font-size: 13px;
    color: #888888;
}

.help-card-date{
    font-size: 12px;
    color: #546184;
    text-align: end;
    margin: 0;
}

.help-question-container{
    background: #FFFFFF;
    width: 100%;
    /* min-width: 300px; */
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    margin: 0.5em;
}
.help-question-title{
    font-size: 16px;
    color: #1E1E1E;
    font-weight: 600;
    margin: 0;
}
.help-question-paragraph{
    font-size: 14px;
    color: #1E1E1E;
    margin: 0;
}

#text-content{
    display: none;
    transition: all ease-in 0.2s;
}

@media screen and (max-width: 660px){
    .help-header{
        padding-left: 0.5em;
        padding-right: 0.5em;
   }
}