.viewer-container{
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 100;
  background: #ffffff;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px;
}

.viewer-header-container{
    display: flex;
    padding: 1em;
}
.viewer-header-icons{
    background-color: #F5F5F5;
    font-size: 15px;
    border-radius: 7px;
    padding: 0.3em ;
    display: flex;
    justify-content: space-around;
        
}
.viewer-header-icons i{
    color: #A6A6A6;
    padding: 0.1em 0.3em;
    cursor: pointer;
}

.source{
    font-size: 11px;
    color: rgba(69, 69, 69, 1);
    margin: 0;
}
.viewer-table{
    width: 100%;
    table-layout: auto;
    
    border-collapse: collapse;
}
.viewer-table-container{
    background-color: #f2f2f2;
    padding: 1em 2em;
}
.viewer-table th{
    background-color: #EBEDF1;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid #B9B9B9;
    text-align: center;
    max-width: 0.5em;
    padding: 0.5em 0;
}

.viewer-table td{
    background-color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    border: 1px solid #B9B9B9;
    text-align: center;
    max-width: 0.5em;
    padding: 0.5em 0;
}

/* customSelect.css */

/* .custom-select__control {
    background-color: #f8f9fa;
    border-color: #ced4da;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
  } */

  .custom-select-label{
    font-size: 14px;
    color: #757575;
    margin: 0;
    padding: 0.5em 0.2em;
  }
  .custom-select-container{
    width: 100%;
  }

  .custom-select__control{
    background-color: transparent !important;
    border: 1px solid #D9D9D9 !important;
    border-radius: 8px !important;
    font-size: 16px;
    font-family: 'Open Sans' !important;
    
  }
  .custom-select__option {
    font-size: 15px !important;
    font-family: 'Open Sans' !important;
  }

  .custom-select__placeholder {
    font-size: 16px !important;
    font-family: 'Open Sans' !important;
  }

  .custom-color-picker{
    border-radius: 100%;
    height: 22px;
    width: 25px;
    padding: 0;
  }

  .viewer-action{
    color: #0E8FE5;
    font-size: 12px;
    cursor: pointer;
  }
  .viewer-action i{
    color: #0E8FE5;
  }

  .viewer-switch{
    display: flex;
    align-items: center;
  }
  .viewer-switch .form-check-input{
    height: 2em;
    width: 3em;  
  }
  .viewer-switch .form-check-label{
    font-size: 15px;
    color: #333333;
    padding: 0 1em;
  }

  .button-dark-empty{
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    color: #272727;
    background: transparent;
    border-radius: 6px;
    border: 2px solid #272727;
    padding: 0.4em;
    cursor: pointer;
  }
  .button-dark-empty:hover{
    background-color: #272727;
    color: #ffffff;
  }
  .viewer-menu-header{
    display: flex;
    align-items: end;
    padding: 0 1em;
    height: 4em;
  }
  .viewer-menu-header div{
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    margin: 0 0.5em;
    margin-top: 3em;
    padding: 0 0.8em;
    padding-top: 0.5em;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    cursor: pointer;
  }
  .viewer-menu-header p{
    font-size: 13px;
    font-weight: 900;
    margin: 0;
    color: #A1A1A1;
  }
  .viewer-menu-header i{
    color: #A1A1A1;
  }

.active{
    color: #3E5BC0 !important;
    /* background: transparent !important;  */
  }
.viewer-active {
  color: #3E5BC0 !important;
}

.inactive{
  color: #787878;
}

.viewer-input{
  width: 100%;
  border: 1px solid #D9D9D9;
  /* color: #757575; */
  border-radius: 8px;
  font-size: 16px;
  padding: 0.4em 0.8em;
  transition: all 100ms;
}
/* .viewer-input:disabled{
  color: #888888;
} */
.viewer-input:focus{
  outline: none;
  border-color: #2684ff;
}

.viewer-input::-webkit-inner-spin-button,
.viewer-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Ocultar los chevrons en Firefox */
  .viewer-input {
    -moz-appearance: textfield;
  }

.feester{
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  display: flex; justify-content: center;
  align-items: center;
}

.viewer-breadcrumb{
  font-size: 15px;
  color: #787878;
}

.viewer-label-annotation{
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #757575;
}
  