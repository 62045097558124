.favorite-main-container{
    width: 100%;
    padding: 3em 5em;
}
.favorite-table{
    width: 100%;
}
.favorite-table tr{
    border-bottom: 1px solid #E1E1E1;
}

.favorite-table th{
    font-size: 16px;
    color: #515151;
    height: 3em;
    font-weight: 600;
    white-space: nowrap;
    /* display: flex;
    flex-wrap: nowrap;
    align-items: center; */
}
.favorite-table td{
    font-size: 14px;
    color: #515151;
    height: 4em;
    font-weight: 500;
    /* overflow: hidden;
    white-space: wrap; */
}

.favorite-tester{
    background-color: transparent;
    border: none;
    font-size: 25px;
    font-weight: 700;
    color: #333333;
    /* margin: 2px; */
}
.favorite-tester::after{
    display: none;
}

.favorite-tester:hover{
    background-color: transparent !important;
    color: #333333 !important;
}
.favorite-tester:active {
    background-color: transparent !important;
    color: #333333 !important;
}
.favorite-tester:checked {
    background-color: transparent !important;
    color: #333333 !important;
}
.favorite-tester:focus {
    background-color: transparent !important;
    color: #333333 !important;
}
.favorite-tester:visited {
    background-color: transparent !important;
    color: #333333 !important;
}
.favorite-tester:focus-visible {
    background-color: transparent !important;
    color: #333333 !important;
}
.favorite-tester:focus-within {
    background-color: transparent !important;
    color: #333333 !important;
}
.favorite-tester:disabled {
    background-color: transparent !important;
    color: #333333 !important;
}
.favorite-tester:enabled {
    background-color: transparent !important;
    color: #333333 !important;
}

.last-row{
    cursor: pointer;
    /* color: #777777 !important; */
}
.last-row:hover{
    text-decoration: underline;
    color: #2684ff;
}
.table-container{
    width: 100%;
    
    overflow: auto;
}

@media screen and (max-width: 660px){
    .favorite-main-container{
        padding: 1em 1em;
    }
}