.search-main-container{
  padding: 0 2.5em;
  width: 100%;
  box-sizing: content-box;
}

.search-button{
    font-size: 18px;
    padding: 0.2rem 3rem;
    border-radius: 10px;
    background-color: #DEE3F0;
    border: none;
    transition: background-color 0.2s;
}
.search-button:hover{
    background-color: #303950;
    color: #fff
}
.search-button:disabled{
    background: #dddddd;
    color: #fff;
    cursor: default;
}

.search-card-container{
    padding-left: 2em;
}

.search-select-container{
    width: 100%;
    
  }

  .search-select__control{
    background-color: transparent !important;
    border: 1px solid #D9D9D9 !important;
    border-radius: 10px !important;
    padding: 0.2em 0.3em;
    font-size: 16px;
    font-family: 'Open Sans' !important;
    
  }
  .search-select__option {
    font-size: 14px !important;
    font-family: 'Open Sans' !important;
    
  }

  .search-select__placeholder {
    font-size: 16px !important;
    font-family: 'Open Sans' !important;
    color: #8D8D8D !important;
  }

.search-params-container{
  border-right: 1px solid #dddddd;
  padding-right: 2.5em;
}

.popular-container{
  padding: 1em 0;
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.popular-term{
  
  text-wrap: nowrap;
  font-size: 13px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 10px;
  padding: 0.2em 1.5em;
  margin-right: 1em;
  cursor: pointer;
}

.popular-term:hover{
  text-decoration: underline;
}


@media screen and (max-width: 660px) {
  .search-main-container{
    padding: 0 0.5em;
    box-sizing: border-box;
  }
  .search-params-container{
    box-sizing: border-box;
    padding: 0 0.5em;
  }
}
